import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET,SELECTED_GROUPS,SELECTED_DATES} from "../../constants/ActionTypes";
import moment from "moment";
const INIT_STATE = {
  token: null,
  initURL: '',
  authUser: null,
  selectedGroups:["Select Group"],
  selectedDates:[moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {...state, initURL: action.payload};
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
        selectedGroups:["Select Group"]
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SELECTED_GROUPS: {
      return {
        ...state,
        selectedGroups: action.payload,
      };
    }
    case SELECTED_DATES:{
      return {
        ...state,
        selectedDates: action.payload,
      };
    }
    default:
      return state;
  }
}
