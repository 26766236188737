import React, {Component} from "react";
import {Scrollbars} from "react-custom-scrollbars";

class CustomScrollbars extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = { top: 0 };
        this.handleScrollFrame = this.handleScrollFrame.bind(this);
    }
    handleScrollFrame(values) {
    }

    render() {
        const props = this.props;
        return (
            <Scrollbars  {...props} onScrollFrame={this.handleScrollFrame} autoHide
            renderTrackHorizontal={props => <div {...props}
            style={{display: 'none'}} className="track-horizontal"/>}/>
        );
    }
}

export default CustomScrollbars;
