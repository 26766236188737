import { WEBLEADS_DATA, CHAT_LEADS, CHAT_DETAILS, GA_AUDIENCE_OVERVIEW, GA_DIMENSION_METRICS } from '../../constants/ActionTypes'

const INIT_STATE = {
  web:[],
  chat:[],
  chatDetails:[],
  googleAudienceOverview: [],
  gADimensionMetrics:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WEBLEADS_DATA: {
      return {...state, web: action.payload};
    }
    case CHAT_LEADS: {
      return {...state, chat: action.payload};
    }
    case CHAT_DETAILS: {
      return {...state, chatDetails: action.payload};
    }
    case GA_AUDIENCE_OVERVIEW: {
      return {...state, googleAudienceOverview: action.payload}
    }
    case GA_DIMENSION_METRICS: {
      return {...state, gADimensionMetrics: action.payload}
    }
    default:
      return state;
  }
}
