import { DASHBOARD_RECORDS,FETCH_GRAPH } from '../../constants/ActionTypes'

const INIT_STATE = {
  dashboardCounts:[],
  loadingGraph:false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_RECORDS: {
      return {...state, dashboardCounts: action.payload};
    }
    case FETCH_GRAPH: {
      return {...state, loadingGraph: action.payload};
    }
    default:
      return state;
  }
}
