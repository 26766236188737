import React from "react";
import {Button, Form, Input, Typography} from "antd";
import {connect} from "react-redux";

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {footerText} from "util/config";

const FormItem = Form.Item;
const { Title } = Typography;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <img alt="example" src={require('assets/images/main-logo.png')} style={{ marginBottom:"30px", width:"230px" }}/>
          <div className="gx-app-login-main-content">

            <div className="gx-app-login-content" >
              <Title level={3} className="gx-text-left">Log in to your account</Title>
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem label="Email Address" required={false} className="gx-m-0 gx-font-weight-semi-bold">
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem label="Password" required={false} className="gx-mb-20 gx-font-weight-semi-bold">
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" size="large" block htmlType="submit">
                    <IntlMessages id="app.userAuth.login"/>
                  </Button>

                  <div className="gx-layout-footer-content" >
                    Powered By
                    <b style={{color: "#a22b2b",fontFamily: 'NoirPro',fontWeight: 400}}>{footerText}</b>
                  </div>
                </FormItem>
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
