// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

export const CALLRAILS_CALLS = 'CALLRAILS_CALLS'

export const WEBLEADS_DATA = 'WEBLEADS_DATA'
export const AFFILIATE_GROUPS = 'AFFILIATE_GROUPS'
export const CHAT_LEADS = 'CHAT_LEADS'
export const CHAT_DETAILS = 'CHAT_DETAILS'
export const FETCH_DETAILS_LOADER = 'FETCH_DETAILS_LOADER'
export const GA_AUDIENCE_OVERVIEW = 'GA_AUDIENCE_OVERVIEW'
export const GA_DIMENSION_METRICS = 'GA_DIMENSION_METRICS'
export const CLEAR_AFFILLIATE_GROUPS = 'CLEAR_AFFILLIATE_GROUPS'
export const DASHBOARD_RECORDS = 'DASHBOARD_RECORDS'
export const FETCH_GRAPH = 'FETCH_GRAPH'
export const IS_MODAL_VISIBLE = 'IS_MODAL_VISIBLE'
export const SELECTED_GROUPS = 'SELECTED_GROUPS'
export const SELECTED_DATES = 'SELECTED_DATES'
export const CHATS = 'CHATS'
export const SET_SESSION_STATUS = 'SET_SESSION_STATUS'

export const CALL_ALERTS = 'CALL_ALERTS'
export const CALL_DETAILS = 'CALL_DETAILS'
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'

