import {
    CALLRAILS_CALLS
} from '../../constants/ActionTypes'

const INIT_STATE = {
  calls:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CALLRAILS_CALLS: {
      return {...state, calls: action.payload};
    }
    default:
      return state;
  }
}
